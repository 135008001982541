import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["info"]

  show() {
    this.infoTarget.classList.remove("d-none");
  }

  hide() {
    this.infoTarget.classList.add("d-none");
  }
}
