import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["password", "copyButton"]

  copy(event) {
    // Trouver le conteneur parent le plus proche du bouton qui a été cliqué
    const button = event.currentTarget;
    const passwordContainer = button.closest("[data-copy-password-target='password']");

    // Extraire le texte de mot de passe de ce conteneur spécifique
    const passwordText = passwordContainer.innerText.trim();
    const tempInput = document.createElement("textarea");
    tempInput.value = passwordText;
    document.body.appendChild(tempInput);
    tempInput.select();
    document.execCommand("copy");
    document.body.removeChild(tempInput);

    // Met à jour l'icône du bouton pour indiquer le succès de la copie
    const iconContainer = button.querySelector(".icon");
    if (iconContainer) {
      iconContainer.innerHTML = '<i class="fas fa-check"></i>';
      // Réinitialiser l'icône après un certain délai (par exemple, 2 secondes)
      setTimeout(() => {
        iconContainer.innerHTML = '<i class="fas fa-copy"></i>';
      }, 2000);
    }
  }
}
